import _Vue from 'vue';
import Meta, { MetaInfo } from 'vue-meta';

export type { MetaInfo };

export default {
  install: (Vue: typeof _Vue) => {
    Vue.use(Meta);
  },
};
