import PortalVue from 'portal-vue';
import _Vue from 'vue';

type Data = {
  currentModal: string | null;
};

const Modal = _Vue.extend({
  data(): Data {
    return {
      currentModal: null,
    };
  },
  methods: {
    show(name: string): void {
      this.currentModal = name;
    },
    hide(): void {
      this.currentModal = null;
    },
  },
});

// TODO Ceci est un patch pour l'erreur TS: Type alias 'ModalType' circularly references itself.
// ? A fixer quand on aura migré vers Vue 3
interface ModalType extends _Vue {
  currentModal: string | null;
  show(name: string): void;
  hide(): void;
}

declare module 'vue/types/vue' {
  // Vue.$modal
  interface VueConstructor {
    $modal: ModalType;
  }

  // this.$modal
  interface Vue {
    $modal: ModalType;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    Vue.use(PortalVue);

    const modalInstance = new Modal();
    Vue.$modal = modalInstance;
    Vue.prototype.$modal = modalInstance;
  },
};
