import * as rudderanalytics from 'rudder-sdk-js';
import _Vue from 'vue';

import {
  BusinessVariant,
  Freelance,
  FreelanceMissionProposalAnswerEnum,
  MarketSituationInput,
  SelectionStatus,
} from '@freelancerepublik/graphql-types';
import { CallToActionUseCase } from '../../api/models/MarketSituation.ui';

/**
 * Documentation: How to add a new event:
 * 1. Write a tracking function that uses rudderanalytics.track
 * 2. Add an entry to the rudderstackFunctions array with the name of the function and the function itself
 *
 * The function will be added to the Vue prototype
 */

declare module 'vue/types/vue' {
  interface Vue {
    $rudderanalytics: typeof rudderanalytics & typeof rudderstackFunctions;
  }
}

// ! we create a dummy object to avoid errors when rudderstack is not initialized
// we use a proxy to log all calls to rudderstack
const dummyRudderstack = new Proxy(
  {},
  {
    get:
      (target, propKey) =>
      (...args: any) => {
        console.log(`rudderstack.${String(propKey)}`, ...args);
      },
  }
);

function trackUserSignup(flow: 'first_use' | 'invitation') {
  rudderanalytics.track('User signed up', {
    flow,
  });
}

function trackUserCreated(user: Record<string, any>) {
  rudderanalytics.track('User Created', {
    user,
  });
}

function trackUserUpdated({
  firstUse,
  user,
}: {
  firstUse?: {
    is_completed?: boolean;
    step: number;
    step_name: string;
    completion_rate: number;
    variant: BusinessVariant;
  };
  user: Record<string, any>;
}) {
  rudderanalytics.track('User Updated', {
    first_use: firstUse,
    user,
  });
}
function trackMissionAssumed({
  firstUse,
  mission,
}: {
  firstUse?: {
    is_completed?: boolean;
    step: number;
    step_name: string;
    completion_rate: number;
    variant: BusinessVariant;
  };
  mission: Record<string, any>;
}) {
  rudderanalytics.track('Mission Assumed', {
    first_use: firstUse,
    mission,
  });
}

function trackUserLogin() {
  rudderanalytics.track('User logged in');
}

function trackFreelanceMissionInterest({
  missionId,
  freelanceId,
  answer,
  source,
}: {
  missionId: string;
  freelanceId: string;
  answer: FreelanceMissionProposalAnswerEnum;
  source: 'proposalsPipeline' | 'emailAnswer';
}) {
  rudderanalytics.track('Freelance answers proposal', {
    missionId,
    freelanceId,
    answer,
    source,
  });
}

function trackFreelanceMarketSituationEdit(ms: MarketSituationInput, uc: CallToActionUseCase) {
  rudderanalytics.track('Freelance updates situation', {
    marketSituation: ms,
    useCase: uc,
  });
}

function trackInsiderNotifyingFreelance({
  action,
  shouldNotBeNotified,
  status,
  freelanceId,
  weightedScore,
  missionId,
}: {
  action: string;
  shouldNotBeNotified: boolean;
  status: SelectionStatus;
  freelanceId: string;
  weightedScore: number;
  missionId: string;
}) {
  rudderanalytics.track('Insider notifies freelance', {
    action,
    shouldNotBeNotified,
    status,
    freelanceId,
    weightedScore,
    missionId,
  });
}

function trackInsiderClickingFreelanceDetailsTab(tabLabel: string) {
  rudderanalytics.track('Insider clicks freelance details page', {
    'Tab clicked': tabLabel,
  });
}

type PreselectionData = {
  mission: {
    _id: string;
    title: string;
    profile: string;
  };
  selections: {
    _id: string;
    fullname: string;
  }[];
};
function trackInsiderCancelingPreselection(data: PreselectionData) {
  rudderanalytics.track('Insider cancels preselection basket', data);
}

function trackInsiderAddFreelanceToPreselection(selectAndNotify: boolean, data: PreselectionData) {
  const eventName = selectAndNotify
    ? 'Insider validates and notify a preselection basket'
    : 'Insider validates a preselection basket';

  rudderanalytics.track(eventName, data);
}

function trackEditKeySkillsProposal({
  missionId,
  freelanceId,
  source,
}: {
  missionId: string;
  freelanceId: string;
  source: 'app' | 'admin';
}) {
  if (source === 'admin') {
    rudderanalytics.track('Insider edits freelance key skills proposal', { missionId, freelanceId });
  } else {
    rudderanalytics.track('Freelance edits key skills proposal', { missionId, freelanceId });
  }
}

type SelectionButtonData = {
  freelance: Freelance;
  missionId: string;
  sort: string;
  refinements: any;
  presets: string[];
};

function trackSelectionButtonClick(data: SelectionButtonData) {
  // @ts-expect-error
  rudderanalytics.track('Insider clicks selection button', data);
}

function trackUIClicked(data: any) {
  rudderanalytics.track('UI Clicked', data);
}

export function trackEngagementInboxOpened({
  origin,
}: {
  userId: string;
  origin: 'CandidateQualificationPage' | 'OpportunityPipelineCandidateCard' | 'CanditateDetailsPage';
}) {
  rudderanalytics.track('Engagement Inbox Opened', { origin });
}

const rudderstackFunctions = {
  trackUserLogin,
  trackUserSignup,
  trackUserCreated,
  trackUserUpdated,
  trackMissionAssumed,
  trackFreelanceMissionInterest,
  trackFreelanceMarketSituationEdit,
  trackEngagementInboxOpened,
  trackInsiderNotifyingFreelance,
  trackInsiderClickingFreelanceDetailsTab,
  trackInsiderCancelingPreselection,
  trackInsiderAddFreelanceToPreselection,
  trackEditKeySkillsProposal,
  trackSelectionButtonClick,
  trackUIClicked,
};

export default {
  install: (Vue: typeof _Vue) => {
    if (process.env.VUE_APP_RUDDERSTACK_WRITE_KEY && process.env.VUE_APP_RUDDERSTACK_DATA_PLANE) {
      rudderanalytics.load(process.env.VUE_APP_RUDDERSTACK_WRITE_KEY, process.env.VUE_APP_RUDDERSTACK_DATA_PLANE);

      Vue.prototype.$rudderanalytics = { ...rudderanalytics, ...rudderstackFunctions };
    } else {
      Vue.prototype.$rudderanalytics = dummyRudderstack;
    }
  },
};
