import { Cloudinary } from 'cloudinary-core';
import _Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $cloudinary: Cloudinary;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    const cl = new Cloudinary({
      cloud_name: process.env.VUE_APP_CLOUDINARY_NAME,
    });

    Vue.prototype.$cloudinary = cl;
  },
};
