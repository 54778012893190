import * as Sentry from '@sentry/browser';
import _Vue from 'vue';
import VueRouter, { Route } from 'vue-router';

declare module 'vue/types/vue' {
  interface Vue {
    $sentry: typeof Sentry & { handleRouting(navigationPromise: Promise<Route>): Promise<void | Route> };
  }
}

export default {
  install: (Vue: typeof _Vue, { sentry }: { sentry: typeof Sentry }) => {
    const originalVueConfigWarnHandler = Vue.config.warnHandler;

    /**
     * Since sentry doesn't track Vue warnings by default, we manually capture warnings.
     * Note: Vue.config.warnHandler is not called in production environment
     */
    Vue.config.warnHandler = (msg: string, vm: _Vue, trace: string): void => {
      sentry.captureMessage(`${msg} - ${trace.trim()}`, sentry.Severity.Warning);

      if (typeof originalVueConfigWarnHandler === 'function') {
        originalVueConfigWarnHandler(msg, vm, trace);
      }
    };

    async function handleRouting(navigationPromise: Promise<Route>): Promise<void | Route> {
      const { isNavigationFailure, NavigationFailureType } = VueRouter;

      // If not redirected by the router naviguation guard
      // https://router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures
      // Prevents applicative redirections from being logged to Sentry as errors.
      return navigationPromise.catch((err: unknown) => {
        if (!isNavigationFailure(err, NavigationFailureType.redirected)) {
          sentry.captureException(err);
        }
      });
    }

    Vue.prototype.$sentry = sentry;
    Vue.prototype.$sentry.handleRouting = handleRouting;
  },
};
