import _Vue from 'vue';

type Options = {
  apiKey: string;
};

export default {
  install: (Vue: typeof _Vue, { apiKey }: Options) => {
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;

    document.body.appendChild(script);
  },
};
